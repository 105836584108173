@import url("https://fonts.googleapis.com/css2?family=Lato&display=swap");
@import "normalize.css/normalize.css";

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Lato", sans-serif;
}

.home,
.products,
.team {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}
.reports .datepicker {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
}

.reports .datepicker .sub-button {
  max-width: fit-content;
}

.nav-icons svg {
  fill: #60c525;
}
.open-btn svg {
  fill: white;
}

.sidebar-link:hover svg {
  fill: white;
}
.overview,
.reports {
  max-width: 1000px;
  margin: auto;
  margin-bottom: 5rem;
}
.overview h1,
.reports h1 {
  margin-top: 3rem;
  font-size: 4rem;
  margin-bottom: 3rem;
}

.site-container {
  display: flex;
  width: 100%;
  height: 100%;
}
.content-container {
  width: 100%;
}
.header-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.header-right {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.header-right__buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.header-right__buttons span {
  background-color: #ffffff6b;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  border-radius: 999px;
  width: 50px;
  height: 50px;
  transition: 0.4s ease-in;
  cursor: pointer;
}
.header-right__buttons span:hover {
  background-color: white;
}
.header-right__buttons svg {
  fill: black;
  width: 20px;
  height: 20px;
}
.header-right__user {
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: center;
}
.header-right__user-img {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 999px;
  overflow: hidden;
}
.header-right__user-info {
  color: black;
}
.user-info__name {
  font-weight: bold;
}
.user-info__profession {
  font-size: 0.8rem;
}

ul.breadcrumb {
  padding: 10px 16px;
  list-style: none;
  background-color: #ffffff6b;
  border-radius: 999px;
  transition: 0.4s ease-in;
}
ul.breadcrumb:hover {
  background-color: white;
}
ul.breadcrumb li {
  display: inline;
  font-size: 15px;
}
ul.breadcrumb li + li:before {
  padding: 8px;
  color: black;
  content: "/\00a0";
}
ul.breadcrumb li a:not(:nth-child(2)) {
  color: black;
  font-weight: bold;
  text-decoration: none;
}
ul.breadcrumb li a {
  color: black;
  text-decoration: none;
}
ul.breadcrumb li a:hover {
  color: #01447e;
  text-decoration: underline;
}

.dx-field-button-item {
  text-align: center !important;
}
.header-left__mobile {
  display: none;
}
.sidebar-nav {
  background: white;
  width: 250px;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: sticky;
  top: 0;
  transition: 350ms;
  z-index: 10;
  box-shadow: -2px 3px 9px black;
}
.sidebar-close {
  display: none;
}
@media only screen and (max-width: 1250px) {
  .sidebar-nav {
    position: fixed;
    display: none;
  }
  .sidebar-nav.show {
    display: block;
  }
  .header-left__mobile {
    display: flex;
  }
  .header-left__mobile svg {
    fill: black;
  }
  .overview,
  .reports {
    padding-left: 3rem;
    padding-right: 3rem;
    margin-left: 0;
    margin-right: 0;
  }
  .header-left {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
  }
  .header-left__mobile {
    font-size: 2rem;
    cursor: pointer;
  }
  .sidebar-close {
    font-size: 3rem;
    display: flex;
    cursor: pointer;
  }
}

@media only screen and (max-width: 660px) {
  .header-right__buttons {
    display: none;
  }
}
